import { IResult, ICategorizedResponse } from "redmond";

import * as actionTypes from "./constants";

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetFromDate {
  type: actionTypes.SET_FROM_DATE;
  date: Date | null;
}

export const setFromDate = (date: Date | null): ISetFromDate => ({
  type: actionTypes.SET_FROM_DATE,
  date,
});

export interface ISetUntilDate {
  type: actionTypes.SET_UNTIL_DATE;
  date: Date | null;
}

export const setUntilDate = (date: Date | null): ISetUntilDate => ({
  type: actionTypes.SET_UNTIL_DATE,
  date,
});

export interface IFetchLocationCategories {
  type: actionTypes.FETCH_LOCATION_CATEGORIES;
  queryString: string;
}

export const fetchLocationCategories = (
  queryString: string
): IFetchLocationCategories => ({
  type: actionTypes.FETCH_LOCATION_CATEGORIES,
  queryString,
});

export interface ISetLocationCategories {
  type: actionTypes.SET_LOCATION_CATEGORIES;
  categories: ICategorizedResponse[];
}

export const setLocationCategories = (
  categories: ICategorizedResponse[]
): ISetLocationCategories => ({
  type: actionTypes.SET_LOCATION_CATEGORIES,
  categories,
});

export interface ISetLocation {
  type: actionTypes.SET_LOCATION;
  location: IResult | null;
}

export const setLocation = (location: IResult | null): ISetLocation => ({
  type: actionTypes.SET_LOCATION,
  location,
});

export interface ISetLocationAutocompleteError {
  type: actionTypes.SET_LOCATION_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setLocationAutocompleteError = (
  error: boolean
): ISetLocationAutocompleteError => ({
  type: actionTypes.SET_LOCATION_AUTOCOMPLETE_ERROR,
  error,
});

export type ExperiencesSearchActions =
  | IFetchLocationCategories
  | ISetLocationCategories
  | ISetLocation
  | ISetLocationAutocompleteError
  | ISetFromDate
  | ISetUntilDate
  | ISetOpenDatesModal;
