import { connect, ConnectedProps } from "react-redux";

import { ExperiencesSearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getLocation,
  getLocationCategoriesLoading,
  getLocationCategories,
  getHasLocationAutocompleteError,
  getFromDate,
  getUntilDate,
} from "../../reducer/index";
import { IResult } from "redmond";
import H from "history";

interface IStateProps {
  location: IResult | null;
  fromDate: Date | null;
  untilDate: Date | null;
}

const mapStateToProps = (state: IStoreState) => {
  return {
    location: getLocation(state),
    locationCategories: getLocationCategories(state),
    locationCategoriesLoading: getLocationCategoriesLoading(state),
    hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
  };
};

interface OwnProps {
  className?: string;
  onSearch?: (
    history: H.History,
    searchFromMap?: boolean,
    searchExperiencesNear?: boolean
  ) => void;
  displaySearchOnChange?: boolean;
  showTravelerSelection?: boolean;
  showSearchButton?: boolean;
  saveDatesOnClose?: boolean;
  hideCalendarSeparator?: boolean;
  showTotalTravelers?: boolean;
}
interface IDispatchProps {
  resetFilters: () => void;
}
const mapDispatchToProps = () => {
  return {
    resetFilters: () => {
      null;
    },
  };
};
const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: OwnProps
) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type ExperiencesSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedExperiencesSearchControl = connector(
  ExperiencesSearchControl
);
