export const FETCH_LOCATION_CATEGORIES =
  "experiencesSearch/FETCH_LOCATION_CATEGORIES";
export type FETCH_LOCATION_CATEGORIES = typeof FETCH_LOCATION_CATEGORIES;

export const SET_LOCATION_CATEGORIES =
  "experiencesSearch/SET_LOCATION_CATEGORIES";
export type SET_LOCATION_CATEGORIES = typeof SET_LOCATION_CATEGORIES;

export const SET_LOCATION = "experiencesSearch/SET_LOCATION";
export type SET_LOCATION = typeof SET_LOCATION;

export const SET_LOCATION_AUTOCOMPLETE_ERROR =
  "experiencesSearch/SET_LOCATION_AUTOCOMPLETE_ERROR";
export type SET_LOCATION_AUTOCOMPLETE_ERROR =
  typeof SET_LOCATION_AUTOCOMPLETE_ERROR;

export const SET_FROM_DATE = "experiencesSearch/SET_FROM_DATE";
export type SET_FROM_DATE = typeof SET_FROM_DATE;

export const SET_UNTIL_DATE = "experiencesSearch/SET_UNTIL_DATE";
export type SET_UNTIL_DATE = typeof SET_UNTIL_DATE;

export const SET_OPEN_DATES_MODAL = "experiencesSearch/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;
