import { Box, Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import "./styles.scss";
import { ExperiencesSearchConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import { CallState } from "redmond";
import { Skeleton } from "@material-ui/lab";
import { SUBTITLE, TITLE } from "./textConstants";
import { ClientContext } from "../../App";
import {
  PORTAL_TITLE,
  SEARCH_EXPERIENCES_TITLE,
} from "../../lang/textConstants";
import { ExperiencesSearchControl } from "./components/ExperiencesSearchControl";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";
import { MobileExperiencesSearchControl } from "./components/MobileExperiencesSearchControl";

export interface IExperiencesSearchProps
  extends ExperiencesSearchConnectorProps,
    RouteComponentProps {}

export const ExperiencesSearch = ({
  fetchRewardsAccounts,
  largestValueAccount,
  fetchRewardsAccountsCallState,
  setLocation,
}: IExperiencesSearchProps) => {
  const { sessionInfo } = useContext(ClientContext);
  const { matchesMobile, matchesDesktop } = useDeviceTypes();

  const getTitleToDisplay = () => {
    if (
      (fetchRewardsAccountsCallState === CallState.Success ||
        fetchRewardsAccountsCallState === CallState.Failed) &&
      largestValueAccount
    ) {
      return TITLE(largestValueAccount);
    } else {
      return <Skeleton className="title-loading" />;
    }
  };

  useEffect(() => {
    document.title = SEARCH_EXPERIENCES_TITLE;
    fetchRewardsAccounts(false, sessionInfo);

    setTimeout(() => window.scrollTo(0, 0), 0);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  return (
    <>
      <Box
        className={clsx("experiences-search-root", {
          desktop: matchesDesktop,
          mobile: matchesMobile,
        })}
      >
        {matchesMobile ? (
          <MobileExperiencesSearchControl onSelectLocation={setLocation} />
        ) : (
          <Box className="experiences-search-container">
            <Box className="experiences-search-titles-and-fields-wrapper">
              <Typography variant="h1" className="search-title">
                {getTitleToDisplay()}
              </Typography>
              <Typography variant="h2" className="search-subtitle">
                {SUBTITLE}
              </Typography>
              <ExperiencesSearchControl />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
