import { all } from "redux-saga/effects";
import { sagas as experiencesRewards } from "../modules/rewards";
import { sagas as experiencesSearch } from "../modules/search";

export function* rootSaga() {
  yield all([
    experiencesRewards.watchFetchRewardsAccounts(),

    experiencesSearch.watchFetchLocationCategories(),
  ]);
}
